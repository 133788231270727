
import { defineComponent, onMounted, ref,watch } from 'vue';
import ApexChart  from "apexcharts";
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  props:{
    totalTentativa: {
      type: Number,
      require: true
    },
    totalErro :{
      type: String,
      require: true
    },
    totalSucesso :{
      type:  String,
      require: true
    },
    showGraficos : Boolean, 
    loadingCards:  Boolean,
    lastDate: String,
    statusOS: {
      type : Number,
      default: null
    }
  },  
  components:{
    ContentLoader
  },
    setup(props){
      
      const showGrafico = ref(true)
      const loading = ref(true)
      const series:any = ref([0,0]);
      const colors = ref(["var(--cor_indicadores_1)","var(--cor_indicadores_4)",])
      function geraOptions(labelsRef, refColors, isMobile = false) {
      const newOptions = {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: '100%',
          height: 300,
          type: 'pie',
          options: "chart",
          series: [],
        },
        stroke: {
          width: 0
        },
        legend: {
          horizontalAlign: 'left', 
          position: isMobile ? 'bottom' : 'right'
        },
        labels: labelsRef,
        colors: refColors,
        fill: {
          colors: refColors,
        },
      }

      return newOptions;
    }

    let chartInstance;

    watch(() => props.showGraficos,() => {
      showGrafico.value = props.showGraficos     
    })

    watch(() => props.loadingCards,() => {
      loading.value = props.loadingCards
    })
      
    watch(() => [props.totalErro, props.totalSucesso], ()=> {
        chartInstance.render();
        if(props.totalErro || props.totalSucesso){

          series.value = [Number(props.totalSucesso),Number(props.totalErro)]
          let colorsData:any = [];
         
            colorsData = colors.value
            chartInstance.updateSeries(series.value);
            chartInstance.updateOptions(geraOptions(["Sucessos", "Erros"], colorsData));
        }  
    },{deep: true}) 

      onMounted(() => {
        const options = geraOptions(["Sucesso", "Erros"], colors.value);
        series.value = [0,0]
        chartInstance = new ApexChart(document.querySelector("#grafico-erroSucesso"), {
            ...options,
            series: [0,0],
        });
      })



    return {
        chartInstance,
        showGrafico,
        loading
    }
    }
})
