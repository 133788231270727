
import {defineComponent,watch,ref,Ref, onMounted} from 'vue'
import { getOrdenServicos , getLinkPdfPreOs, linkPdfOs } from '@/services/ListaOS'
import moment from 'moment'
import { ContentLoader } from 'vue-content-loader'
import { useStore } from 'vuex'
import useAlert from "@/composables/Alert";
import { Modal } from "bootstrap";

interface DateOS {
    cliente: string,
    codConcessionaria: string,
    data: string,
    email: string,
    modelo: string,
    pacote: string,
    placa: string,
    retornoIntegracao: string,
    xmlIntegracao: string,
    xmlretorno: string,
    nos: string,
    revisao: string,
    telefone:  string
    codigointerno: string | null
}

export default defineComponent({
    props: {
        codOSselected : String,
        nomeConsultor: String,
        codConsultor: String
    },
    components:{
        ContentLoader
    },
    setup(props){
        const dataOS : Ref<DateOS | undefined>  = ref()
        const store = useStore()
        const animate = ref(true)
        const concessionariaInfo = ref()
        const nomeConsultor = ref(props.nomeConsultor)
        const collapseOpenXmlIntegracao = ref(false)
        const collapseOpenXmlRetorno = ref(false)
        const linkPdf = ref()
        const linkPdfOS = ref()
        const xmlIntegracao = ref()
        const xmlRetorno = ref()
        const { showTimeAlert } = useAlert();
        const refModal = ref()
            
        const getOs = async () =>  {
            animate.value = true
            try {
                dataOS.value = await getOrdenServicos(concessionariaInfo.value.codConcessionaria,props.codOSselected)
            } catch (error) {
                console.log(error)
                showTimeAlert("Não foi possível completar a solicitação", "error");
                reset()
                refModal.value.hide();
            }
        }
        
        const getPdf = () => {
            const seachPdf = {
                placa:   dataOS.value?.placa,
                revisao:   dataOS.value?.revisao,
                codConsultor:   props.codConsultor,
                pacote : dataOS.value?.pacote                           
            }

            const response = getLinkPdfPreOs(concessionariaInfo.value.codConcessionaria,seachPdf)  
            
            return response   
        }

        const getPdfOS = async () => {
            const payload = {
                codigoInterno : dataOS.value?.codigointerno,
                placa: dataOS.value?.placa              
            }

            if(dataOS.value?.placa && dataOS.value?.codigointerno){
                try{

                    const { link } = await linkPdfOs(concessionariaInfo.value.codConcessionaria,payload)
                    linkPdfOS.value = link

                }catch{
                    animate.value = false
                }
            }    
        }

        const reset = () => {
            xmlIntegracao.value = ''
            linkPdf.value = ''
        }

        watch(() => props.codOSselected, async () => {
            reset()
            linkPdfOS.value = null
            concessionariaInfo.value = store.getters.concessionariaSelectedInfo
            nomeConsultor.value = props.nomeConsultor
            collapseOpenXmlIntegracao.value = false 
            collapseOpenXmlRetorno.value = false
            await getOs()
            await getPdfOS()
            await getPdf()?.then((response) => {
                linkPdf.value = response.link
                
                animate.value = false 
            }).catch(() => {
                reset()
            })

            xmlIntegracao.value = dataOS.value?.xmlIntegracao   
            xmlRetorno.value = dataOS.value?.xmlretorno         
            animate.value = false            
        })

        function setCopy(text) {
             showTimeAlert('Código copiado com sucesso!');

             navigator.clipboard.writeText(text);
         }

         onMounted(() => {
            const element = document.getElementById("modal-os");
            refModal.value = new Modal(element);
         })

        return {
            concessionariaInfo,
            nomeConsultor,
            dataOS,
            moment,
            animate,
            collapseOpenXmlIntegracao,
            collapseOpenXmlRetorno,
            linkPdf,
            xmlIntegracao,
            xmlRetorno,
            setCopy,
            linkPdfOS
        }
    }
    
})
